<div class="poster">
  

  <a [routerLink]="'/films/' +model.masterId"
     [title]="'Go to ' + model.title ">
    <img class="poster__image" loading="lazy" [src]=" model['externalPlaybillUrl']"
         srcset="{{model['externalPlaybillUrl']}} 1x {{model['imageUrl']}} 2x"
         [alt]="model.title " appImgMissingDirective>
  </a>

  <div class="poster__content">
    <a *ngIf="model.title "
       [routerLink]="model.masterId"
       [title]="model.title"
       class="poster__title colored-hover">
      
    </a>
  </div> 

</div>
