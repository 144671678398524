export class ProductBaseModel {

  public id: number;
  public title: string;
  public masterId?: number;
  public masterIdSource?: string;
  public category: string;
  public creaId?: number;
  public cinetelId?: number;
  public tmdbId?: number;
  public parentId?: number;
  public publicId: string;
  public eventTypeId: number;
  public playbill?: string;
  public externalPlaybillUrl: string;
  public backdropImage?: string;
  public urlTrailer: string;
  public tmdb_Image? : string;
  public tmdb_Backdrop? : string;
  public tmdb_Trailer? : string;
  public cast: string;
  public director?: string;
  public length: number;
  public year: number;
  public plot: string;
  public genres?: Array<string>;
  public producer?: string;
  public projectionType: string;
  public language: string;
  public age?: string;
  public extraInfo?: string;
  public releaseDate?: string;
  public notes?: string;
  public critics?: string;
  public instructions?: string;
  public instructionsUrl?: string;
  public originId: number;
  public originPublicId: string;
  public syntheticSubsystemId: number;
  public violenceIcon: boolean;
  public sexIcon: boolean;
  public weaponsIcon: boolean;
  public strongLanguageIcon: boolean;
  public alcoholOrDrugsIcon: boolean;
  public discriminationsIcon: boolean;
}
