import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialog,  MatDialogConfig,  MatDialogRef} from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
//import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { DialogService } from '../../services/dialog.service';


@Component({
    selector: 'trailer-dialog',
    templateUrl: './trailer-dialog.component.html',
    styleUrls: ['./trailer-dialog.component.css']
})
export class TrailerDialogComponent implements OnInit {
    //form: FormGroup;
    description:string;
    safeSrc: SafeResourceUrl;

    constructor(
        private dialogRef: MatDialogRef<TrailerDialogComponent>,
        private dialog: DialogService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.description;
        this.safeSrc=  data.safeSrc;
        console.log("in  constructor TrailerDialogComponent"+ this.safeSrc);  
    }


    // constructor(private dialog : MatDialog,
    //             @Inject(MAT_DIALOG_DATA) private trailer: any,
    //             private dialogRef: MatDialogRef<TrailerDialogComponent>) {

    //     this.description = trailer.description;

    // }


    ngOnInit() {
        console.log("in ngOnInit TrailerDialogComponent"+ this.safeSrc);
        // this.form = this.fb.group({
        //     description: [this.description, []]
        // });

    }



    close() {

        this.dialogRef.close();

    }

    save() {
        this.dialogRef.close();
    }
}


export function openTrailerDialog(dialog: MatDialog, mydialog:any) {

    const config = new MatDialogConfig();

    config.disableClose = true;
    config.autoFocus = true;
    config.panelClass = "modal-panel";
    config.backdropClass = "backdrop-modal-panel";

    config.data = {
        //...trailer
    };

    const dialogRef = dialog.open(TrailerDialogComponent, config);

    return dialogRef.afterClosed();
}









