import { Component,Input } from '@angular/core';
import { ProductMasterModel } from 'app/shared/models/productMaster.model';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent 
{
  @Input() model:ProductMasterModel;
  @Input() isMovie: boolean;
  
  constructor () {}
}
