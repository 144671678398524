import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NotFoundComponent} from './core/components/not-found/not-found.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'Home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  {path: 'Cinema', loadChildren: () => import('./films/films.module').then(m => m.FilmsModule)},
  {path: 'Teatro', loadChildren: () => import('./teatro/teatro.module').then(m => m.TeatroModule)},
  {path: 'Concerti', loadChildren: () => import('./concerti/concerti.module').then(m => m.ConcertiModule)},

  {path: '404', component: NotFoundComponent, pathMatch: 'full'},

  {path: '**', redirectTo: '', pathMatch: 'full'}

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//     initialNavigation: 'enabledBlocking'
//   })],
//   exports: [RouterModule]
// })
export class AppRoutingModule { }
