<ng-template #matTrailerDialog>
    <div cdkDragBoundary=".cdk-overlay-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="background: black;">
      <div class="dialog-title-container" cdkDragHandle>
        <a href="javascript:void(0);" (click)="trailerDialog.closeAll()">
          <mat-icon aria-label="cambia immagine">close</mat-icon>
        </a>
        <h2 matDialogTitle>Play YouTube Trailer</h2>
      </div>
      <mat-dialog-content class="header-content-dialog">
  
        <div class="video-movie-main"> 
  
          <iframe [src]="safeSrc"  allowfullscreen></iframe>
        </div>
  
      </mat-dialog-content>
  
    </div>
  </ng-template>


  <!-- <div *ngIf="product" class="video-movie-main"> -->


<!-- <div >

    <h2 mat-dialog-title>{{description}}</h2>

    <mat-dialog-content>
        <div  class="video-movie-main">
  
            <iframe [src]="safeSrc"  allowfullscreen></iframe>
          </div>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button mat-raised-button (click)="close()">Close</button>

        <button mat-raised-button color="primary" (click)="save()">Save</button>

    </mat-dialog-actions>

</div> -->







