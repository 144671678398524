import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { ProductMasterModel } from '../models/productMaster.model';
import { ProductBaseModel } from '../models/productBase.model';
import { Product_LocaleModel } from '../models/product_locale.model';



@Injectable()
export class ProductsService {

  baseUrl: string;
  apiKey: string;
  language: string;
  region: string;


  constructor(private http: HttpClient) {
    this.baseUrl = 'https://localhost:44360/';
    //this.baseUrl = 'https://api.test.18tickets.it/';
    //this.apiKey = environment.tickettandoApiKey;
    this.language = 'it-IT';
    this.region = 'IT'
  }

  getProducts(): Observable<ProductMasterModel[]> {
    //return this.http.get(`${this.baseUrl}tv/${type}?api_key=${this.apiKey}&page=${page}&language=${this.language}`)

    const apiUrl=this.baseUrl+'api/GetScheduledProductsMastersMin';
    return this.http.get<ProductMasterModel[]>(apiUrl);
    //   .get(
      // return this.http
      //   .get(`${this.baseUrl}api/GetProductMastersMinScheduled`)
    }

    getMasterMinProductsCached(): Observable<ProductMasterModel[]> {
      //return this.http.get(`${this.baseUrl}tv/${type}?api_key=${this.apiKey}&page=${page}&language=${this.language}`)
  
      const apiUrl=this.baseUrl+'api/GetScheduledProductsMastersMinCached';
      return this.http.get<ProductMasterModel[]>(apiUrl);
      //   .get(
        // return this.http
        //   .get(`${this.baseUrl}api/GetProductMastersMinScheduled`)
      }

      getMasterMinProductsCachedLocally(): Observable<ProductMasterModel[]> {
        return this.http.get<ProductMasterModel[]>("assets/scheduledProductsMastersMin.json");
        }

    GetMaster_ProductBaseByMasterId(id: number): Observable<ProductBaseModel> {
      //return this.http.get(`${this.baseUrl}tv/${type}?api_key=${this.apiKey}&page=${page}&language=${this.language}`)
      //return this.http.get(`${this.baseUrl}api/Products_Venues/GetProducts_Venues`)
        
      var apiUrl=this.baseUrl+'api/GetMaster_ProductBaseByMasterId/'+id;
      var res=this.http.get<ProductBaseModel>(apiUrl);
      return res;
         // .get(`${this.baseUrl}api/GetProductsByMasterId${id}`)
      }

      GetScheduledProductMastersCached(): Observable<ProductBaseModel[]> {
        return this.http.get<ProductBaseModel[]>("assets/scheduledProductsMastersFinal.json"); 
        }


      GetScheduledProductLocalesByMasterIdOrderByPV(id: number): Observable<Product_LocaleModel[]> {
        //return this.http.get(`${this.baseUrl}tv/${type}?api_key=${this.apiKey}&page=${page}&language=${this.language}`)
        console.log(" service GetScheduledProductLocalesByMasterIdOrderByPV called by FilmInCinemasComponent/"+id);
        const apiUrl=this.baseUrl+'api/GetScheduledProductLocalesByMasterIdOrderByPV/'+id;
        var res=this.http.get<Product_LocaleModel[]>(apiUrl);
        
        console.log(" service GetScheduledProductLocalesByMasterIdOrderByPV/"+ id);
        return res;

        }

        GetScheduledProductsLocalesCached(): Observable<Product_LocaleModel[]> {
          return this.http.get<Product_LocaleModel[]>("assets/scheduledProductsLocales.json"); 
          }



  getTheatresOggi(page: number): Observable<any> {
    return this.http.get(`${this.baseUrl}tv/on_the_air?api_key=${this.apiKey}&page=${page}&language=${this.language}`)
  }

  getTheatre(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}tv/${id}?api_key=${this.apiKey}&language=${this.language}`)
  }

  getTheatresProssimamente(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}tv/${id}/videos?api_key=${this.apiKey}`)
  }

  getTheatresTop10(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}tv/${id}/recommendations?api_key=${this.apiKey}`)
  }

  getGenres(): Observable<any> {
    return this.http.get(`${this.baseUrl}genre/tv/list?api_key=${this.apiKey}&language=${this.language}`)
  }

  getTheatresByGenre(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}discover/tv?api_key=${this.apiKey}&language=en-US&sort_by=popularity.desc&page=1&timezone=America%2FNew_York&with_genres=${id}&include_null_first_air_dates=false`);
  }

}
