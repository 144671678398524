<header class="navbar__container" [class.colored__scrolled]="isScrolled">

  <nav class="navbar__nav container">
    <a routerLink="/" class="flex-start navbar__logo">
      <img [ngSrc]="'assets/img/Tickettando_logo_white.png'" alt="Tickettando" width="272" height="58">
    </a>
    <div class="navbar__list">
      <!-- <a mat-button routerLink="" [routerLinkActive]="['activated-route']" [routerLinkActiveOptions]="{exact: true}" aria-label="Home">Home</a> -->
      <a mat-button routerLink="" [routerLinkActive]="['activated-route']" [routerLinkActiveOptions]="{exact: true}" aria-label="Home">Home</a>
      <a mat-button routerLink="Teatro" [routerLinkActive]="['activated-route']" aria-label="Teatro">Teatro</a>
      <a mat-button routerLink="Cinema" [routerLinkActive]="['activated-route']" aria-label="Films">Cinema</a>

      <button mat-icon-button [matMenuTriggerFor]="menu" title="Change Color" aria-label="Change Color">
        <mat-icon>format_color_fill</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div *ngFor="let theme of themeColorList" [class.hidden]="themeColorInit === theme.color" [attr.aria-label]="'Color ' + theme.name">
          <button mat-menu-item (click)="setColorTheme(theme.color)">
            <mat-icon [style.color]="theme.color">lens</mat-icon>
            <span>{{theme.name}}</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </nav>
  

</header>
