import { Component, Input } from '@angular/core';
import { ProductBaseModel } from 'app/shared/models/productBase.model';
import { ProductMasterModel } from 'app/shared/models/productMaster.model';


@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss']
})
export class HomeCardComponent {

  @Input() model: ProductBaseModel;
  @Input() isMovie: boolean;
 
  category = '';

  constructor ( ) {}

  ngOnInit() {
    this.category=this.model.category;
    if(this.category=="Mostre" ||this.category=="Eventi"||this.category=="Sport")
    {
      this.category="Mostre-Eventi";
    }
    //console.log(" in HomeCardComponent:"+ this.model.title+ "cat:"+this.category+ "image:"+this.model.externalPlaybillUrl);
    }
}
