
<ng-template #matTrailerDialog>
<mat-dialog-content>
  <div class="videoWrapper">
    <iframe [src]=[safeUrl] frameborder="0" allowfullscreen></iframe>
  </div>
</mat-dialog-content>

<hr>

<mat-dialog-actions class="app-dialog-actions">
  <button type="button" class="btn btn-primary btn-sm" (click)="close()">Close</button>
</mat-dialog-actions>
</ng-template>