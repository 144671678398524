<div class="poster">
    <a [routerLink]="category + '/'+model.masterId"
     [title]="'Vai a ' + model.title ">
    <img class="poster__image" loading="lazy" [src]=" model['externalPlaybillUrl']"
         [alt]="model.title " appImgMissingDirective>
  </a>
  <div class="poster__content">
    <a *ngIf="model.title "
       [routerLink]="category + '/'+model.masterId"
       [title]="model.title"
       class="poster__title colored-hover">
       {{model.title}}
    </a>
  </div> 
  

