import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
//import { MoviesService } from '../shared/services/movies.service';
import {ProductsService} from '../shared/services/products.service';
import {MovieModel} from '../shared/models/movie.model';
import {ProductMasterModel} from '../shared/models/productMaster.model';
import {ProductBaseModel} from '../shared/models/productBase.model';
import {SeoService} from '../core/services/seo.service';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import {take} from 'rxjs/operators';

import { initializeLinq, IEnumerable } from "linq-to-typescript";
import { HttpClient } from '@angular/common/http';
//import genresJson from '../core/components/appconfigs/genres.json';
import genresJson from '../../assets/allGenres.json';
declare global {
  interface Array<T> extends IEnumerable<T> { }
  interface Uint8Array extends IEnumerable<number> { }
  interface Uint8ClampedArray extends IEnumerable<number> { }
  interface Uint16Array extends IEnumerable<number> { }
  interface Uint32Array extends IEnumerable<number> { }
  interface Int8Array extends IEnumerable<number> { }
  interface Int16Array extends IEnumerable<number> { }
  interface Int32Array extends IEnumerable<number> { }
  interface Float32Array extends IEnumerable<number> { }
  interface Float64Array extends IEnumerable<number> { }
  interface Map<K, V> extends IEnumerable<[K, V]> { }
  interface Set<T> extends IEnumerable<T> { }
  interface String extends IEnumerable<string> { }
}
initializeLinq();

//SwiperCore.use([Pagination]); //pallini inferiori

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 2.3,
    spaceBetween: 20,
    navigation: true,
    watchSlidesProgress: true,
    grabCursor: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      1280: {slidesPerView:8.3, spaceBetween: 20, slidesOffsetBefore: 0, slidesOffsetAfter: 0},
      992: {slidesPerView: 6.3, spaceBetween: 20, slidesOffsetBefore: 0, slidesOffsetAfter: 0},
      768: {slidesPerView: 4.3, spaceBetween: 15, slidesOffsetBefore: 0, slidesOffsetAfter: 0},
      576: {slidesPerView: 3.3, spaceBetween: 15, slidesOffsetBefore: 0, slidesOffsetAfter: 0},
      320: {slidesPerView: 2.3, spaceBetween: 10, slidesOffsetBefore: 10, slidesOffsetAfter: 10},
    }
  };

  contentType = '';
  moviesList: Array<MovieModel> = [];
  selectedMovieTab = 0;
  public genresTabList: string[][] = genresJson;
 
  filmsTabList: string[] = genresJson[0];
  teatroTabList: string[] = genresJson[1];
  concertiTabList : string[] = genresJson[2];
  mostreTabList : string[] = genresJson[3];
  eventiTabList : string[] = genresJson[4];
  sportTabList : string[] = genresJson[5];
  mostre_eventiTabList : string[] = genresJson[6];


  productsList: Array<ProductBaseModel> = [];
  filmsProducts: Array<ProductBaseModel> = [];
  selectedFilms: Array<ProductBaseModel> = [];
  teatroProducts: Array<ProductBaseModel> = [];
  selectedTeatro:Array<ProductBaseModel> = [];
  concertiProducts: Array<ProductBaseModel> = [];
  selectedConcerti:Array<ProductBaseModel> = [];
  mostreProducts: Array<ProductBaseModel> = [];
  mostre_eventiProducts: Array<ProductBaseModel> = [];
  selectedMostre:Array<ProductBaseModel> = [];
  eventiProducts: Array<ProductBaseModel> = [];
  selectedEventi:Array<ProductBaseModel> = [];
  sportProducts: Array<ProductBaseModel> = [];
  selectedSport:Array<ProductBaseModel> = [];
  selectedMostre_Eventi:Array<ProductBaseModel> = [];

  selectedFilmsTab = 0;
  selectedTeatroTab = 0;
  selectedConcertiTab = 0;
  selectedMostreTab = 0;
  selectedEventiTab = 0;
  selectedSportTab = 0;
  selectedMostre_EventiTab = 0;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    //private moviesService: MoviesService,
    private productsService: ProductsService,
    private seo: SeoService
  ) 
  { 
    this.contentType = this.router.url.split('/')[1];
  }

  ngOnInit() {
    console.log(" in HomeComponent");
    this.seo.generateTags({
      title: 'Tickettando',
      description: 'Biglietti Tickets per Film, Teatri, Eventi, Concerti, Musei, Cinema - Home Page',
      image: 'assets/img/Tickettando_logo_white.png'
    });
    //this.getMovies('now_playing', 1);
    this.getProducts();
  }



  tabFilmsChange(tab) {
    this.selectedFilmsTab = tab.index;
    var genre=this.filmsTabList[tab.index];
    if(tab.index==0) {this.selectedFilms=this.filmsProducts} 
    else if (tab.index==1) {this.selectedFilms=this.filmsProducts.orderBy(f=>f.title).toArray();} 
    else{ this.selectedFilms=this.filmsProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
    console.debug("selectedFilms:"+tab.index +"   "+ genre +" Count:"+this.selectedFilms.count() );
  }

  tabTeatroChange(tab) {
    this.selectedTeatroTab = tab.index;
    var genre=this.teatroTabList[tab.index];
    if(tab.index==0) {this.selectedTeatro=this.teatroProducts} 
    else if (tab.index==1) {this.selectedTeatro=this.teatroProducts.orderBy(p=>p.title).toArray();} 
    else{ this.selectedTeatro=this.teatroProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
    console.debug("selectedTeatro:"+tab.index +"   "+ genre +" Count:"+this.selectedTeatro.count() );
  }
  tabConcertiChange(tab) {
    this.selectedConcertiTab = tab.index;
    var genre=this.concertiTabList[tab.index];
    if(tab.index==0) {this.selectedConcerti=this.concertiProducts} 
    else if (tab.index==1) {this.selectedConcerti=this.concertiProducts.orderBy(p=>p.title).toArray();} 
    else{ this.selectedConcerti=this.concertiProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
    console.debug("selectedConcerti:"+tab.index +"   "+ genre +" Count:"+this.selectedConcerti.count() );
  }  
  // tabEventiChange(tab) {
  //   this.selectedEventiTab = tab.index;
  //   var genre=this.eventiTabList[tab.index];
  //   if(tab.index==0) {this.selectedEventi=this.eventiProducts} 
  //   else if (tab.index==1) {this.selectedEventi=this.eventiProducts.orderBy(p=>p.title).toArray();} 
  //   else{ this.selectedEventi=this.eventiProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
  //   console.debug("selectedEventi:"+tab.index +"   "+ genre +" Count:"+this.selectedEventi.count() );
    
  // }
  // tabMostreChange(tab) {
  //   this.selectedMostreTab = tab.index;
  //   var genre=this.mostreTabList[tab.index];
  //   if(tab.index==0) {this.selectedMostre=this.mostreProducts} 
  //   else if (tab.index==1) {this.selectedMostre=this.mostreProducts.orderBy(p=>p.title).toArray();} 
  //   else{ this.selectedMostre=this.mostreProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
  //   console.debug("selectedMostre:"+tab.index +"   "+ genre +" Count:"+this.selectedMostre.count() );
  // }
  // tabSportChange(tab) {
  //   this.selectedSportTab = tab.index;
  //   var genre=this.sportTabList[tab.index];
  //   if(tab.index==0) {this.selectedSport=this.sportProducts} 
  //   else if (tab.index==1) {this.selectedSport=this.sportProducts.orderBy(p=>p.title).toArray();} 
  //   else{ this.selectedSport=this.sportProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
  //   console.debug("selectedSport:"+tab.index +"   "+ genre +" Count:"+this.selectedSport.count() );
  // }

  tabMostre_EventiChange(tab) {
    this.selectedMostre_EventiTab = tab.index;
    var genre=this.mostre_eventiTabList[tab.index];
    if(tab.index==0) {this.selectedMostre_Eventi=this.mostre_eventiProducts} 
    else if (tab.index==1) {this.selectedMostre_Eventi=this.mostre_eventiProducts.orderBy(p=>p.title).toArray();} 
    else{ this.selectedMostre_Eventi=this.mostre_eventiProducts.where(p => p.genres!=null && p.genres.contains(genre)).toArray() ;}
    console.debug("selectedMostre_Eventi:"+tab.index +"   "+ genre +" Count:"+this.selectedMostre_Eventi.count() );
  }



  getProducts(): void {
    //this.productsService.getMasterMinProductsCachedLocally().subscribe(resp => {
      this.productsService.GetScheduledProductMastersCached().subscribe(resp => {
      this.productsList = resp;
      

      this.filmsProducts= this.productsList.filter(p=>p.category=="Cinema");
      this.selectedFilms= this.filmsProducts;
      this.teatroProducts= this.productsList.filter(p=>p.category=="Teatro");
      this.selectedTeatro=this.teatroProducts;
      this.concertiProducts= this.productsList.filter(p=>p.category=="Concerti");
      this.selectedConcerti=this.concertiProducts;
      this.mostreProducts= this.productsList.filter(p=>p.category=="Mostre");
      this.selectedMostre=this.mostreProducts;
      this.eventiProducts= this.productsList.filter(p=>p.category=="Eventi");
      this.selectedEventi=this.eventiProducts;
      this.sportProducts= this.productsList.filter(p=>p.category=="Sport");
      this.selectedSport=this.sportProducts;
      this.mostre_eventiProducts= this.productsList.filter(p=>p.category=="Mostre" ||p.category=="Eventi"||p.category=="Sport");
      this.selectedMostre_Eventi=this.mostre_eventiProducts;
      console.debug("productsList"+this.productsList.length);
      
    });
  }




}

