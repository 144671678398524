import { Component,Input, OnInit } from '@angular/core';
import { ProductMasterModel } from 'app/shared/models/productMaster.model';


@Component({
  selector: 'app-master-card',
  templateUrl: './master-card.component.html',
  styleUrls: ['./master-card.component.scss']
})
export class MasterCardComponent {

  // @Input() product:
@Input() model:ProductMasterModel;
@Input() isMovie: boolean;

constructor () {}

ngOnInit() {
  console.log("in MasterCardComponent ")
  }

}
