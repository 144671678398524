import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {CustomMaterialModule} from './custom-material.module';
import {MovieCardComponent} from './components/poster-card-view/poster-card.component';
import {ImgMissingDirective} from './directives/img-missing.directive';
import { MasterCardComponent } from './components/master-card-view/master-card.component';
import { ProductCardComponent } from './components/product-card.view/product-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PlayYoutubeVideoDialogComponent } from './dialogs/play-youtube-video-dialog/play-youtube-video-dialog.component';
import { TrailerDialogComponent } from './dialogs/trailer-dialog/trailer-dialog.component';
import { DialogService } from './services/dialog.service';
import { TrailerDialogService } from './services/trailer-dialog.service';

import { ConfirmComponent } from './dialogs/confirm/confirm.component';


@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    MatDialogModule,

  ],
  exports: [
    CommonModule,
    CustomMaterialModule,
    MovieCardComponent,
    MasterCardComponent,
    ImgMissingDirective,
    MatDialogModule
  ],
  declarations: [
    MovieCardComponent,
    ImgMissingDirective,
    MasterCardComponent,
    ProductCardComponent,
    PlayYoutubeVideoDialogComponent,
    TrailerDialogComponent,
    ConfirmComponent

  ]
})
export class SharedModule 
{  constructor
  (
    // private dialog: DialogService,
    // private trailerDialog: TrailerDialogService
    
    ) 
  {} 
}
