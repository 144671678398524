<section class="home-main-content"  style="color: orange;">
  <a href="https://brassgroup.organizzatori.18tickets.it/film/26316"> <img style="margin-top: 15px" src="assets/img/MAX_GAZZE_MusicaeLoci.jpg" alt="MAX_GAZZE_MusicaeLoci"></a>
  <!-- <img src="assets/img/MAX_GAZZE_MusicaeLoci.jpg" -->
  <!-- [alt]="" appImgMissingDirective> -->
 <!-- Benvenuti su Tickettando -->
</section>

<div class="container">

  <!-- TEATRO -->
  <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/Teatro" > <mat-icon>theater_comedy</mat-icon><h2> Teatro</h2> </button>
      <div>
        <mat-tab-group  (selectedTabChange)="tabTeatroChange($event)" [selectedIndex]="selectedTeatroTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of teatroTabList" class="tab__item" [label]="tab">
            <div *ngIf="tab == teatroTabList[selectedTeatroTab]">
              <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
                <ng-template swiperSlide *ngFor="let product of selectedTeatro | slice:0:200">
                  <app-home-card [model]="product" ></app-home-card>
                </ng-template>
              </swiper>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>

  <!-- FILMS -->
  <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/Cinema" > <mat-icon>movie</mat-icon><h2> Film</h2> </button>
      <div >
        <mat-tab-group  (selectedTabChange)="tabFilmsChange($event)" [selectedIndex]="selectedFilmsTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of filmsTabList" class="tab__item" [label]="tab">
            <div *ngIf="tab == filmsTabList[selectedFilmsTab]">
              <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
                <ng-template swiperSlide *ngFor="let product of selectedFilms | slice:0:200">
                  <app-home-card [model]="product"  [category] = "tab" ></app-home-card>
                </ng-template>
              </swiper>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>



  <!-- CONCERTI-->
  <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/Concerti" > <mat-icon>music_video</mat-icon><h2> Concerti</h2> </button>
      <div>
        <mat-tab-group  (selectedTabChange)="tabConcertiChange($event)" [selectedIndex]="selectedConcertiTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of concertiTabList" class="tab__item" [label]="tab">
            <div *ngIf="tab == concertiTabList[selectedConcertiTab]">
              <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
                <ng-template swiperSlide *ngFor="let product of selectedConcerti | slice:0:200">
                  <app-home-card [model]="product" ></app-home-card>
                </ng-template>
              </swiper>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>

  <!-- MOSTRE -->
  <!-- <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/mostre" > <mat-icon>museum</mat-icon><h2> Mostre</h2> </button>
      <div>
        <mat-tab-group  (selectedTabChange)="tabMostreChange($event)" [selectedIndex]="selectedMostreTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of mostreTabList" class="tab__item" [label]="tab">
            <div *ngIf="tab == filmsTabList[selectedFilmsTab]">
              <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
                <ng-template swiperSlide *ngFor="let product of selectedMostre | slice:0:200">
                  <app-home-card [model]="product" ></app-home-card>
                </ng-template>
              </swiper>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section> -->

    <!-- MOSTRE_EVENTI -->
    <section class="products__container">
      <div class="content__container">
        <button class="home__category"  mat-button routerLink="/Mostre_eventi" > <mat-icon>museum</mat-icon><h2> Mostre Eventi</h2> </button>
        <div>
          <mat-tab-group  (selectedTabChange)="tabMostre_EventiChange($event)" [selectedIndex]="selectedMostre_EventiTab" mat-align-tabs="end">
            <mat-tab *ngFor="let tab of mostre_eventiTabList" class="tab__item" [label]="tab">
              <div *ngIf="tab == mostre_eventiTabList[selectedMostre_EventiTab]">
                <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
                  <ng-template swiperSlide *ngFor="let product of selectedMostre_Eventi | slice:0:200">
                    <app-home-card [model]="product" ></app-home-card>
                  </ng-template>
                </swiper>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </section>


  <!-- EVENTI-->
  <!-- <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/Eventi" > <mat-icon>museum</mat-icon><h2> Eventi</h2> </button>
      <div>
        <mat-tab-group  (selectedTabChange)="tabEventiChange($event)" [selectedIndex]="selectedEventiTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of eventiTabList" class="tab__item" [label]="tab">
            <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
              <ng-template swiperSlide *ngFor="let product of selectedEventi | slice:0:200">
                <app-home-card [model]="product" ></app-home-card>
              </ng-template>
            </swiper>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section> -->

  <!-- SPORT -->

  <!-- <section class="products__container">
    <div class="content__container">
      <button class="home__category"  mat-button routerLink="/mostre" > <mat-icon>sports_soccer</mat-icon><h2> Sport</h2> </button>
      <div>
        <mat-tab-group  (selectedTabChange)="tabSportChange($event)" [selectedIndex]="selectedSportTab" mat-align-tabs="end">
          <mat-tab *ngFor="let tab of sportTabList" class="tab__item" [label]="tab">
            <swiper [config]="config" style="display: flex; flex-direction: column-reverse;" >
              <ng-template swiperSlide *ngFor="let product of selectedSport | slice:0:200">
                <app-home-card [model]="product" ></app-home-card>
              </ng-template>
            </swiper>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section> -->



</div>
