import { NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core//core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SeoService} from './core/services/seo.service';



import {SharedModule} from './shared/shared.module';
import {NavbarComponent} from './core/components/navbar/navbar.component';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {SwiperModule} from 'swiper/angular';
import {DatePipe, NgOptimizedImage} from '@angular/common';
import { ProductsService } from './shared/services/products.service';
import { CachingInterceptor } from './core/interceptors/caching.interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomMaterialModule } from './shared/custom-material.module';
import { HomeModule } from './home/home.module';
import { MoviesService } from './shared/services/movies.service';
import {MatDialogModule,} from "@angular/material/dialog";
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    SwiperModule,
    MatTabsModule,
    CustomMaterialModule,
    NgOptimizedImage,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CoreModule,
    MatDialogModule

  ],
  exports: [
    CustomMaterialModule,
    SharedModule,
    MatDialogModule

  ],
  providers: [
    ProductsService,
    MoviesService,
    SeoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }