<div 

  [ngClass]="{
    'orange-theme': checkSelectedTheme(themeColorEnum.SCURO),
    'green-theme': checkSelectedTheme(themeColorEnum.CHIARO)
  }">

  <!--Desktop Navbar-->
  <app-navbar (changeColorTheme)="changeColorTheme($event)"></app-navbar>

  <!--Main Routing-->
  <!-- <main role="main" style="min-height: 100vh; height: 100vh"  class="mat-app-background"> -->
    <main role="main" style="min-height: 88vh" class="mat-app-background">
    <router-outlet></router-outlet>
  </main>

  <!-- Footer Component -->
  <app-footer></app-footer>

</div>
