import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SharedModule } from 'app/shared/shared.module';

//import { Bookmark } from '../../../core/model/bookmark';

@Component({
  selector: 'app-play-youtube-video-dialog',
  templateUrl: './play-youtube-video-dialog.component.html',
  styleUrls: ['./play-youtube-video-dialog.component.scss']
})
export class PlayYoutubeVideoDialogComponent implements OnInit {

  safeUrl: any;

  constructor(
    private dialogRef: MatDialogRef<PlayYoutubeVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _sanitizer: DomSanitizer
  ) {
    //this.bookmark = data.bookmark;
    console.log(" in concstuctor PlayYoutubeVideoDialogComponent");
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/${data.youtubeVideoId+'?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1'}");
    // this.safeUrl=this.safeUrl+"?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1"
  console.log("safeurl:"+this.safeUrl)  ;
  }

  ngOnInit() {
    console.log(" in PlayYoutubeVideoDialogComponent");
  }

  close() {
    this.dialogRef.close('Play Youtube Video Closed');
  }

}
